<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Diagnostic Reminder</v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-app-bar>

    <div class="col-md-4 col-sm-12 col-xs-12" v-if="!loading">
      <v-form
        @submit.prevent="submitDiagnosticForm"
        ref="formDiagnostic"
        :disabled="form.$busy"
      >
        <v-menu
          ref="menu2"
          v-model="diagnosticTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="form.diagnostic_time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              class="mt-2 mb-5"
              v-model="form.diagnostic_time"
              label="Notification Time"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details="auto"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="diagnosticTimeMenu"
            v-model="form.diagnostic_time"
            full-width
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="diagnosticTimeMenu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu2.save(form.diagnostic_time)"
            >
              OK
            </v-btn>
          </v-time-picker>
        </v-menu>

        <label class="text-field-label">Day of Week</label>
        <v-btn-toggle class="mt-2 mb-8" v-model="form.diagnostic_day" multiple>
          <v-btn
            v-for="(day, index) in days"
            :key="index"
            :disabled="form.$busy"
          >
            {{ day }}
          </v-btn>
        </v-btn-toggle>

        <label class="text-field-label">Notification Message</label>
        <v-textarea
          outlined
          auto-grow
          required
          class="mt-2"
          hide-details="auto"
          v-model="form.diagnostic_message"
          :error-messages="form.$getError('message')"
        ></v-textarea>

        <div class="d-flex py-5 justify-end">
          <v-btn
            :loading="form.$busy"
            type="submit"
            color="primary"
            width="150px"
            depressed
          >
            Save
          </v-btn>
        </div>
      </v-form>
    </div>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mdiPlus } from '@mdi/js'
import Form from '@/utils/form'

export default {
  name: 'DiagnosticReminderNotificationPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon
  },

  created() {
    this.getSettings()
  },

  data() {
    return {
      loading: false,
      form: new Form({
        diagnostic_day: null,
        diagnostic_time: null,
        diagnostic_message: null
      }),
      diagnosticTimeMenu: false,
      diagnosticTime: null,
      diagnosticDay: null,
      icons: {
        add: mdiPlus
      },
      snackbar: {
        show: false,
        message: null,
        color: ''
      },
      days: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
    }
  },

  methods: {
    async getSettings() {
      this.loading = true
      this.$api
        .get(`settings/notification`)
        .then(response => {
          this.form = new Form(response.data.data)
        })
        .finally(() => (this.loading = false))
    },

    submitDiagnosticForm() {
      this.form.$clearErrors()
      this.form.$busy = true
      this.$api
        .post(`settings/notification/diagnostic`, {
          time: this.form.diagnostic_time,
          day: this.form.diagnostic_day,
          message: this.form.diagnostic_message
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .then(() => this.showSnackbar('Successfully updated!', 'success'))
        .finally(() => (this.form.$busy = false))
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  }
}
</script>
